import "./App.css";
import { Route, BrowserRouter as Router, Switch, withRouter } from "react-router-dom";
import { React, useState, useEffect } from "react";
import { Container, Grid, Paper } from "@material-ui/core";
import { Suspense, lazy } from "react";
import NavigationBar from "./sharedComponents/navigationBar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import trLocale from "date-fns/locale/tr";
import { SnackbarProvider } from "notistack";
import { UserContext } from "./auth/UserContex";
import PrivateRoute from "./auth/PrivateRoute";
import PublicRoute from "./auth/PublicRoute";
import useFindUser from "./auth/useFindUser";
import inMemoryJWTManager from "./auth/inMemoryJWTManager";
import { getSessionUser, refreshTokenService } from "./expert/pages/login/services";
import { useHistory } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { trTR } from "@mui/x-data-grid";
const muiTheme = createMuiTheme({
  typography: {
    // fontFamily: "Poppins Regular",
    color: "#fcf0fe",
  },
  trTR,
  palette: {
    background: {
      default: "#fcf0fe",
    },
    text: {
      // primary: "#ffffff",
    },
    primary: {
      main: "#741981",
    },
    secondary: {
      main: "#7B5EC6 ",
    },
    warning: {
      main: "#f3c4bf",
    },
    error: {
      main: "#de6053",
    },
  },
});
const DashboardPage = lazy(() => import("./sharedPages/dashboard/dashboard"));
const UrgentPage = lazy(() => import("./expert/pages/urgent/urgentPage"));
const UrgentAnswerPage = lazy(() => import("./expert/pages/urgent/UrgentAnswerPage"));
const AppointmentPage = lazy(() => import("./expert/pages/appointment/appointmentPage"));
const AdvicePage = lazy(() => import("./expert/pages/advice/advicePage"));
const TextPage = lazy(() => import("./expert/pages/text/textPage"));
const VideoPage = lazy(() => import("./expert/pages/video/videoPage"));
const PngPage = lazy(() => import("./sharedPages/Png"));
const EducationPage = lazy(() => import("./expert/pages/education/educationPage"));
const PublicationsPage = lazy(() => import("./expert/pages/publications/publicationsPage"));
const ShopPage = lazy(() => import("./expert/pages/shop/shopPage"));
const LandingPage = lazy(() => import("./expert/pages/landing/landingPage"));
const LoginPage = lazy(() => import("./expert/pages/login/loginPage"));
const LogoutPage = lazy(() => import("./expert/pages/login/logoutPage"));
const NotificationPage = lazy(() => import("./expert/pages/notification/notificationPage"));
const AstrologManagement = lazy(() => import("./expert/pages/user/AstrologManagementPage"));
const UserManagement = lazy(() => import("./expert/pages/user/UserManagementPage"));
const ShareManagement = lazy(() => import("./expert/pages/user/ShareManagementPage"));
const UserPayment = lazy(() => import("./expert/pages/userPayments/UserPaymentPage"));
const PrivatePage = lazy(() => import("./sharedPages/gizlilik-politikasi"));
const Support = lazy(() => import("./sharedPages/support/support"));
const CardPage = lazy(() => import("./expert/pages/cards/cardPage"));

const PaymentPage = lazy(() => import("./payment/payment"));
const SuccessPage = lazy(() => import("./payment/success"));
const Failureage = lazy(() => import("./payment/failure"));
function App() {
  const { user, setUser, isLoading } = useFindUser();
  const [isAppReady, setIsAppReady] = useState(false);
  const history = useHistory();
  const shopPath = ["/failure", "/success"];
  useEffect(() => {
    // const resp = refreshTokenService();
    // setIsAppReady(true);
    // inMemoryJWTManager.setToken(resp.token, resp.tokenExpiration);
    let currentLocation = window.location.pathname;
    const fetchData = async () => {
      const resp = await getSessionUser();
      if (resp.state === "success" && resp.astrolog.company) {
        setUser(resp.astrolog);
      }
    };
    if (!shopPath.includes(currentLocation)) {
      fetchData();
    }
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Router>
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
            <UserContext.Provider value={{ user, setUser, isLoading }}>
              {user && <NavigationBar />}
              <Container className='Main-Container' maxWidth='xl'>
                <Grid container spacing={2} alignContent='center' alignItems='center' justify='center'>
                  <Switch>
                    <Suspense fallback={<div>"Yükleniyor....."</div>}>
                      <PrivateRoute exact path='/logout' component={LogoutPage} />
                      <PrivateRoute exact path='/appointment' component={AppointmentPage} />
                      <PrivateRoute exact path='/advice/:period' component={AdvicePage} />
                      <PrivateRoute exact path='/text' component={TextPage} />
                      <PrivateRoute exact path='/video/:token' component={VideoPage} />
                      <PrivateRoute exact path='/education' component={EducationPage} />
                      <PrivateRoute exact path='/publication' component={PublicationsPage} />
                      <PrivateRoute exact path='/shop' component={ShopPage} />
                      <PrivateRoute exact path='/dashboard' component={DashboardPage} />
                      <PrivateRoute exact path='/urgent/:questionId?' component={UrgentPage} />
                      <PrivateRoute exact path='/urgent-answer/:questionId?' component={UrgentAnswerPage} />
                      <PrivateRoute exact path='/notification' component={NotificationPage} />
                      <PrivateRoute exact path='/card' component={CardPage} />
                      <PrivateRoute exact path='/astrolog-management' component={AstrologManagement} />
                      <PrivateRoute exact path='/share-management' component={ShareManagement} />
                      <PrivateRoute exact path='/user-management' component={UserManagement} />
                      <PrivateRoute exact path='/user-payments' component={UserPayment} />
                      <PublicRoute exact path='/png' restricted={false} component={PngPage} />
                      <PrivateRoute exact path='/' restricted={true} component={LandingPage} />
                      <PublicRoute exact path='/payment/:basketId' restricted={false} component={PaymentPage} />
                      <PublicRoute exact path='/success' restricted={false} component={SuccessPage} />
                      <PublicRoute exact path='/failure' restricted={false} component={Failureage} />
                      <PublicRoute exact path='/login/:params?' restricted={true} component={LoginPage} />
                      <PublicRoute exact path='/gizlilik-politikasi' restricted={false} component={PrivatePage} />
                      <PublicRoute exact path='/support' restricted={false} component={Support} />
                    </Suspense>
                  </Switch>
                </Grid>
              </Container>
            </UserContext.Provider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
