import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ResetPassword = () => {
  const [appState, setAppState] = useState({
    email: "",
    password: "",
    repeatPassword: "",
  });

  const updateState = (key, event) => {
    console.log(key + event.target.value);
    setAppState({ ...appState, [key]: event.target.value });
  };

  const handleSubmit = () => {
      console.log(appState);
    // your submit logic
  };
  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (appState.password !== appState.repeatPassword) {
      return false;
    }
    return true;
  });

  const handleErrors = (errors) => {
    console.error(errors);
    alert("Lütfen Alanları Kontrol Edin")
  };
  return (
    <div>
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={(errors) => handleErrors(errors)}
      >
        <TextValidator
          label="email"
          onChange={(v) => updateState("email", v)}
          name="email"
          value={appState.email}
          validators={[
            "required",
            "isEmail",
            "minStringLength:12",
            "maxStringLength:64",
          ]}
          errorMessages={[
            "lütfen bir değer girin",
            "lütfen geçerli bir email girin",
            "çok kısa",
            "çok uzun",
          ]}
        />
        <Button variant="outlined" type="submit">Şifremi Sıfırla</Button>
      </ValidatorForm>

    </div>
  );
};

export default ResetPassword;