import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { menuItems } from "./menuItems";
import * as Muicon from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { UserContext } from "../auth/UserContex";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navButton: {
    margin: "auto",
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    //   backgroundColor: "orange",
    flexGrow: 1,
  },
  drawer: {
    width: 250,
  },
}));

const ButtonAppBar = () => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user, isLoading } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (index, path, event) => {
    if (path) {
      history.push(path);
    } else {
      setAnchorEl({ [index]: event.currentTarget });
    }
  };

  const handleClose = (target) => {
    if (target) history.push(target);
    setAnchorEl(null);
  };
  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation];
    return <IconName {...props} />;
  };

  function intersectArrays(a, b) {
    return a.filter(Set.prototype.has, new Set(b));
  }
  let test;
  const checkRoles = (menuItems) => {
    const userHasAccess = intersectArrays(menuItems.roles, user.roles);
    if (userHasAccess.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} style={{ flex: 1 }}>
        {isMobile ? (
          <>
            <IconButton edge='start' color='inherit' aria-label='menu' onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <IconButton
                edge='end'
                className={classes.menuButton}
                color='inherit'
                aria-label='menu'
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                <Avatar alt='Logo' src='/logo.png' style={{ marginRight: "10px" }} />
                {user.name}
              </IconButton>

              {Object.keys(menuItems).map((item, index) => (
                <>
                  {checkRoles(menuItems[item]) && (
                    <span key={index}>
                      <Button color='inherit' onClick={(e) => handleClick(index, menuItems[item].path, e)}>
                        {menuItems[item].icon ? GenerateIcon(menuItems[item].icon) : ""}
                        {menuItems[item].title}
                      </Button>
                      <Menu
                        anchorEl={anchorEl && anchorEl[index]}
                        keepMounted
                        open={anchorEl && Boolean(anchorEl[index])}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        {menuItems[item].subItems.map((menuitems, menuindex) => (
                          <MenuItem key={menuindex} selected={menuitems === item} onClick={() => handleClose(menuitems.path)}>
                            {menuitems.icon ? GenerateIcon(menuitems.icon) : ""}
                            {menuitems.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    </span>
                  )}
                </>
              ))}
            </Drawer>
          </>
        ) : (
          <>
            <IconButton
              edge='end'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              <Avatar alt='Logo' src='/logo.png' style={{ marginRight: "10px" }} />
              {user.name}
            </IconButton>
            <div style={{ position: "absolute", right: 0 }}>
              {Object.keys(menuItems).map((item, index) => (
                <>
                  {checkRoles(menuItems[item]) && (
                    <span key={index}>
                      <Button color='inherit' onClick={(e) => handleClick(index, menuItems[item].path, e)}>
                        {menuItems[item].icon ? GenerateIcon(menuItems[item].icon) : ""}
                        {menuItems[item].title}
                      </Button>
                      <Menu
                        anchorEl={anchorEl && anchorEl[index]}
                        keepMounted
                        open={anchorEl && Boolean(anchorEl[index])}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        {menuItems[item].subItems.map((menuitems, menuindex) => (
                          <MenuItem key={menuindex} selected={menuitems === item} onClick={() => handleClose(menuitems.path)}>
                            {menuitems.icon ? GenerateIcon(menuitems.icon) : ""}
                            {menuitems.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    </span>
                  )}
                </>
              ))}
            </div>
          </>
        )}
      </Toolbar>
    </div>
  );
};

export default ButtonAppBar;
