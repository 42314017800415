import { host } from "../../../config";

const axios = require("axios").default;

const headers = { "Content-Type": "application/json" };

export async function login(email, passw, reCAPTCHAToken) {
  var url = `${host}/auth/login/web`;
  console.log(url);
  let response;
  try {
    response = await axios.post(url, { email, passw, reCAPTCHAToken }, { headers: headers });
    return {
      state: response.data.state,
      message: response.data.message,
      refreshToken: response.data.refreshToken,
      refreshTokenExpiration: response.data.refreshTokenExpiration
    };
  } catch (error) {
    console.log(error);
    return { state: "error", message: "Bir hatayla karşılaşıldı (" + error.message + ")" };
  }
}

export async function getSessionUser(email, passw) {
  var url = `${host}/auth/get-session-user`;
  try {
    const response = await axios.get(url, { headers: headers, withCredentials: true });
    if (response.status === 200) {
      return { state: "success", message: response.data.message, astrolog: response.data.astrolog };
    } else {
      return { state: "error", message: response.data.message };
    }
  } catch (error) {
    return { state: "error", message: "Bir hatayla karşılaşıldı.(Geçersiz Token) " };
  }
}
export async function logout() {
  var url = `${host}/auth/logout/web`;
  try {
    const response = await axios.get(url, { headers: headers, withCredentials: true });
    return response.data;
  } catch (error) {
    console.error(error.message);
  }
}
export async function refreshTokenService(email, passw) {
  var url = `${host}/auth/refresh-token`;
  try {
    const response = await axios.post(url, { headers: headers, withCredentials: true });
    if (response.status === 200) {
      return { state: "success", token: response.data.token, tokenExpiration: response.data.tokenExpiration };
    } else {
      return { state: "error" };
    }
  } catch (error) {
    return { state: "error", message: "Bir hatayla karşılaşıldı.(Geçersiz Token) " };
  }
}
