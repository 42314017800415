import { refreshTokenService } from "../expert/pages/login/services";
const { addMinutes } = require("date-fns");
const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let refreshTimeoutId;

  const refreshToken = (expiration) => {
    const delay = new Date(expiration).getTime() - new Date().getTime();
    // Fire five seconds before JWT expires
    const timeoutTrigger = delay - 5000;
    if (timeoutTrigger > 0) {
      // console.log("🚀 ~ file: inMemoryJWTManager.js ~ line 11 ~ refreshToken ~ timeoutTrigger", timeoutTrigger);
    } else {
      // console.log("buraya girdim ama çıkıyorum");
      return;
    }
    refreshTimeoutId = window.setTimeout(async () => {
      const resp = await refreshTokenService();

      if (resp.state === "error") {
        deleteToken();
      } else {
        const tokenExpiration = addMinutes(new Date(), 1);
        setToken(resp.refreshToken, tokenExpiration);
      }
      // ajaxRefreshToken()
      //     .then(res => {
      //         const { token, tokenExpiration } = res.data;
      //         setToken(token, tokenExpiration);
      //     }).catch(console.error);
    }, timeoutTrigger);
  };

  const abortRefreshToken = () => {
    if (refreshTimeoutId) {
      window.clearTimeout(refreshTimeoutId);
    }
  };
  const getToken = () => inMemoryJWT;

  const setToken = (token, tokenExpiration) => {
    inMemoryJWT = token;
    refreshToken(tokenExpiration);
    return true;
  };

  const deleteToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    return true;
  };

  return {
    getToken,
    setToken,
    deleteToken,
  };
};

export default inMemoryJWTManager();
