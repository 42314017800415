import React from "react";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import Signin from "./components/Signin";
import { Grid } from "@material-ui/core";

const LoginPage = () => {
  const style = {
    padding: "1vw",
  };
  return (
    <Grid container spacing={2} style={style}>
      <Grid item xs={12}>
        <Login />
      </Grid>

      <Grid item xs={12}>
        <ResetPassword />
      </Grid>

      <Grid item xs={12}>
        <Signin />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
