// Eklenen icon adinin ve iconun material ui kutuphanesinde oldugundan emin olun. Aksi takdirde hata verecektir

export const menuItems = {
  anasayfa: {
    path: "/dashboard",
    icon: "Dns",
    title: "Ana Sayfa",
    subItems: [],
    roles: ["admin", "astrolog"],
  },
  bildirim: {
    path: "/notification",
    icon: "Telegram",
    title: "Bildirimler",
    subItems: [],
    roles: ["admin"],
  },
  kartlar: {
    path: "/card",
    icon: "Assignment",
    title: "kartlar",
    subItems: [],
    roles: ["admin"],
  },

  tavsiye: {
    path: "",
    icon: "Comment",
    title: "Yorumlar",
    roles: ["admin"],
    subItems: [
      // {
      //   title: "Günlük Tavsiye",
      //   path: "/advice/thisday",
      //   icon: "AccessibilityNew",
      //   roles: ["admin"],
      // },
      // {
      //   title: "Aylık Tavsiye",
      //   path: "/advice/thismonth",
      //   icon: "EmojiPeople",
      //   roles: ["admin"],
      // },
      // {
      //   title: "Yıllık Tavsiye",
      //   path: "/advice/thisyear",
      //   icon: "Accessibility",
      //   roles: ["admin"],
      // },
      // {
      //   title: "Koçluk",
      //   path: "/advice/certainly",
      //   // icon: "ThumbUp",
      //   icon: "AccessibilityNew",
      //   roles: ["admin"],
      // },
      {
        title: "Hakkında",
        path: "/advice/about",
        icon: "EmojiPeople",
        roles: ["admin"],
      },
      // {
      //   title: "Sakın",
      //   path: "/advice/never",
      //   icon: "PanTool",
      //   roles: ["admin"],
      // },
      // {
      //   title: "Karşılaştır",
      //   path: "/advice/compare",
      //   icon: "CompareArrows",
      //   roles: ["admin"],
      // },
      {
        title: "Koçluk",
        path: "/advice/coaching",
        icon: "BeachAccess",
        roles: ["admin"],
      },
    ],
  },
  randevu: {
    path: "",
    icon: "DateRange",
    title: "Randevu",
    roles: ["admin", "astrolog"],
    subItems: [
      {
        title: "Bugün",
        path: "/appointment",
        icon: "AccessAlarm",
        roles: ["admin", "astrolog"],
      },
      {
        title: "Randevular",
        path: "/text",
        icon: "Today",
        roles: ["admin", "astrolog"],
      },
    ],
  },
  acilyorum: {
    path: "/urgent",
    icon: "FlightTakeoff",
    title: "Acil Yorum",
    subItems: [],
    roles: ["admin", "astrolog"],
  },
  shop: {
    path: "/shop",
    icon: "ShoppingCart",
    title: "Market",
    subItems: [],
    roles: ["admin"],
  },
  education: {
    path: "/education",
    icon: "School",
    title: "Eğitim",
    subItems: [],
    roles: ["admin"],
  },
  publication: {
    path: "/publication",
    icon: "MenuBook",
    title: "Yayınlar",
    subItems: [],
    roles: ["admin"],
  },
  user: {
    path: "",
    icon: "People",
    title: "Yönetim",
    roles: ["admin"],
    subItems: [
      {
        title: "Astrolog Yönetimi",
        path: "/astrolog-management",
        icon: "GroupAdd",
        roles: ["admin"],
      },

      {
        title: "Kullanıcılar",
        path: "/user-management",
        icon: "Person",
        roles: ["admin"],
      },
      {
        title: "Paylaşım Yönetimi",
        path: "/share-management",
        icon: "ScreenShare",
        roles: ["admin"],
      },
      {
        title: "Ödemeler",
        path: "/user-payments",
        icon: "AttachMoney",
        roles: ["admin"],
      },
    ],
  },
  logout: {
    path: "/logout",
    icon: "HighlightOff",
    title: "Çıkış",
    subItems: [],
    roles: ["admin", "astrolog"],
  },
};
// Eklenen icon adinin ve iconun material ui kutuphanesinde oldugundan emin olun. Aksi takdirde hata verecektir
