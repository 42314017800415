import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoginPage from "../sharedPages/login/loginPage";
import { Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DnsIcon from "@material-ui/icons/Dns";
import { useHistory } from "react-router-dom";
import TodayIcon from "@material-ui/icons/Today";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";

import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";

import PanToolIcon from "@material-ui/icons/PanTool";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SchoolIcon from "@material-ui/icons/School";
import TelegramIcon from "@material-ui/icons/Telegram";
import { UserContext } from "../auth/UserContex";
import ButtonAppBar from "./buttonAppBar";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    button: {
      "&:hover": {
        paddingRight: "8px",
        backgroundColor: "#ffffff",
        color: "#d4180a",
      },
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: theme.spacing(1),
    },
    iconButton: {
      marginLeft: theme.spacing(1),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

const NavigationBar = () => {
  const { user, isLoading } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position='static' style={{ background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(154,14,174,1) 17%, rgba(167,32,186,1) 60%, rgba(235,128,250,1) 100%) " }}>
      {/* <Toolbar style={{ flex: 1 }}>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/dashboard");
          }}
          style={{ flex: 1 }}
        >
          <Avatar alt="Logo" src="/logo.png" style={{ marginRight: "10px" }} />
          {user.name}
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/dashboard");
          }}
        >
          <DnsIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Ana sayfa
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/notification");
          }}
        >
          <TelegramIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Bildirim
          </Typography>
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/advice/thisday");
          }}
        >
          <AccessibilityNewIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Günlük Tavsiye
          </Typography>
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/advice/thismonth");
          }}
        >
          <EmojiPeopleIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Aylık Tavsiye
          </Typography>
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/advice/thisyear");
          }}
        >
          <AccessibilityIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Yıllık Tavsiye
          </Typography>
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/advice/certainly");
          }}
        >
          <ThumbUpIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Mutlaka
          </Typography>
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/advice/never");
          }}
        >
          <PanToolIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Sakın
          </Typography>
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/advice/coaching");
          }}
        >
          <BeachAccessIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Koçluk
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/text");
          }}
        >
          <TodayIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Randevular
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/appointment");
          }}
        >
          <AccessAlarmIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Bugün
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/urgent");
          }}
        >
          <FlightTakeoffIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Acil Yorum
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/shop");
          }}
        >
          <ShoppingCartIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Market
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/education");
          }}
        >
          <SchoolIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Eğitim
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/publication");
          }}
        >
          <MenuBookIcon />
          <Typography variant="body2" className={classes.menuButton}>
            Yayınlar
          </Typography>
        </IconButton>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            history.push("/logout");
          }}
        >
          <Typography variant="body2" className={classes.menuButton}>
            Çıkış
          </Typography>
        </IconButton>
        <Dialog maxWidth="xs" open={open} onClose={(v) => setOpen(false)}>
          <DialogContent>
            <LoginPage />
          </DialogContent>
        </Dialog>
      </Toolbar> */}
      <ButtonAppBar></ButtonAppBar>
    </AppBar>

    /*
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="body2" className={classes.title}>
            News
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
    </div>
    */
  );
};

export default NavigationBar;
