import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "./UserContex";
import Loading from "../sharedComponents/Loading/Loading";

export default function PrivateRoute(props) {
  const { user, isLoading } = useContext(UserContext);
  const { component: Component, ...rest } = props;

  if (isLoading) {
    return <Loading />;
  }
  return <Route {...rest} render={(props) => (user ? <Component {...props} /> : <Redirect to='/login' />)} />;
}
