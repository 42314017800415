import { useState, useEffect } from "react";
import axios from "axios";
import { getSessionUser } from "../expert/pages/login/services";

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  async function findUser() {
    const response = await getSessionUser();
    if (response && response.status === 200) {
      setUser(response.astrolog);
      setLoading(false);
    } else {
      setUser();
      setLoading(false);
    }
  }

  useEffect(() => {
    findUser();
  }, []);

  return {
    user,
    setUser,
    isLoading,
  };
}
